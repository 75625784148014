<template>
    <div class="StatReferral">

        <Card>

            <div class="StatReferral__filters">
                    <DateRangePicker
                        :label="$lng.__('lk_webmaster_main', 'Period')"
                        ref="picker"
                        opens="right"
                        :locale-data="{
                        firstDay: 1,
                        format: 'dd.mm.yyyy',
                        daysOfWeek: $lng.__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat').split(','),
                        monthNames: $lng.__('lk_webmaster_main', 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec').split(','),
                    }"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :showDropdowns="true"
                        :autoApply="true"
                        :dateRange="filters.dateRange"
                        v-model="filters.dateRange"
                        @update="setDateRange"
                        :linkedCalendars="false">
                    </DateRangePicker>
                    <FormSelect
                        :label="$lng.__('lk_webmaster_main', 'Group by time')"
                        :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
                        :showResetOption="false"
                        :options="filterByTimeOptions"
                        v-model:selected="filters.groupByTime"
                    ></FormSelect>
            </div>

            <Loading v-if="is_loading"></Loading>

            <div
                v-if="!is_loading && !referrals.length"
                class="StatReferral__empty">
                {{ $lng.__('lk_webmaster_main', 'No results found.') }}
            </div>

            <div v-if="!is_loading && referrals.length" class="StatReferral__table">
                <div class="table__wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Date')"
                                        :isActive="sortBy==='date'"
                                        @sorted="setSortingByDate"
                                    ></SortingIcons>
                                </th>
                                <th class="text-right">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Number of transactions')"
                                        :isActive="sortBy==='number'"
                                        @sorted="setSortingByNumber"
                                    ></SortingIcons>
                                </th>
                                <th class="text-right">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Profit')"
                                        :isActive="sortBy==='profit'"
                                        @sorted="setSortingByProfit"
                                    ></SortingIcons>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="referral in referralsSorted">
                                <td class="text-left text-nowrap">
                                    {{ referral.timestamp }}
                                </td>
                                <td class="text-right">
                                    {{ referral.transaction_count }}
                                </td>
                                <td class="text-nowrap text-right">
                                    {{ referral.profit / 100 }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>


    </div>
</template>

<script>
export default {
    name: 'StatReferral',
    props: [],
    data() {
        return {
            is_loading: true,
            sortBy: 'date',
            sortDirection: 'none',
            filters: {
                dateRange: {
                    startDate: (new Date).addDays(-365),
                    endDate: (new Date)
                },
                groupByTime: 'days'
            },

        }
    },
    computed: {
        serializedFilters() {
            let filters = {
                from: this.filters.dateRange.startDate.getFormattedDate()+' 00:00:00',
                to: this.filters.dateRange.endDate.getFormattedDate()+' 23:59:59',
                limit: 5000,
                group_by_time: this.filters.groupByTime

            }
            return (new URLSearchParams(filters)).toString()
        },
        referrals() {
            return this.$store.state.webmaster.referrals
        },
        // сортировки
        referralsSorted() {
            let sorted = this.referrals.sort((a, b) => {

                // сортировка по date
                if (this.sortBy === 'date') {
                    let first = (typeof a.timestamp === 'object') ? '' : a.timestamp;
                    let last = (typeof b.timestamp === 'object') ? '' : b.timestamp;
                    return first.localeCompare(last);
                }

                // сортировка по pofit
                if (this.sortBy === 'profit') {
                    return parseInt(a.profit) - parseInt(b.profit);
                }

                // сортировка по number
                if (this.sortBy === 'number') {
                    return parseInt(a.referral_count) - parseInt(b.referral_count);
                }

            });

            let needReverse = this.sortDirection === 'desc';

            return needReverse ? sorted.reverse() : sorted;
        },
        // групировка
        filterByTimeOptions() {
            return [
                {id: 'days', name: this.$lng.__('lk_webmaster_main', 'Days')},
                {id: 'months', name: this.$lng.__('lk_webmaster_main', 'Months')},
                {id: 'years', name: this.$lng.__('lk_webmaster_main', 'Years')},
            ];
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(val, oldVal) {
                this.getStatReferral()
            }
        }
    },
    methods: {
        setSorting(by, direction) {
            this.sortBy = by;
            this.sortDirection = direction;
        },
        setSortingByDate(direction) {
            this.setSorting('date', direction);
        },
        setSortingByNumber(direction) {
            this.setSorting('number', direction);
        },
        setSortingByProfit(direction) {
            this.setSorting('profit', direction);
        },
        getStatReferral() {
            this.is_loading = true
            this.$store.dispatch('webmaster/getStatReferral', this.serializedFilters)
                .then(() => {
                    this.is_loading = false
                })
        },
        setDateRange(dateRange) {
            this.filters = {
                ...this.filters,
                dateRange: { startDate: dateRange.startDate, endDate: dateRange.endDate },
            }
        },
    },
    beforeMount() {
        this.getStatReferral()
    },
};
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .StatReferral{
        width: 100%;
        margin-top: 90px;
        @include break(md) {
            margin-top: 0;
        }
        &__filters{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin-top: 15px;
            & > div {
                flex: none;
                margin-right: 15px;
                margin-bottom: 40px;
                @include break(md) {
                    width: calc(50% - 15px) !important;
                    &:nth(2n) {
                        margin-right: 0;
                    }
                }
                @include break(lg) {
                    width: calc(33.33% - 15px) !important;
                    &:nth(3n) {
                        margin-right: 0;
                    }
                }
                @include break(xl) {
                    width: calc(25% - 15px) !important;
                    &:nth(4n) {
                        margin-right: 0;
                    }
                }
            }
        }
        &__table{
            margin: 0 -30px -30px;
        }
        &__empty{
            padding-top: 10px;
            font-weight: 600;
            color: $color-text-muted;
        }
    }
</style>